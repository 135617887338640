import styles from './SoftwareArchitecture.module.scss';
import SectionSimple from '../../../components/SectionSimple/SectionSimple';
import WrapFlex from '../../../components/WrapFlex/WrapFlex';
import TabCard from '../../../components/TabCard/TabCard';
import HeaderAI from '../../../components/HeaderAI/HeaderAI';
import SectionAD from '../../../components/SectionAD/SectionAD';
import TabContent from '../../../components/TabContent/TabContent';

import { useTabCardState } from '../../../utils/useTabCardState';

const solDev = [
  'AI Solutions',
  'Computer vision',
  'Conversational AI',
  'ML Solutions',
  'Developing AI Apps with cognitive services',
  'Building Speech Enabled Apps',
  'Creating Language Understanding solutions',
  'Computer vision',
  'Face Recognition',
  'Knowledge Mining',
  'Models training',
  'OCR',
  'Speech to text',
  'Entity recognition',
  'Speech Translation',
  'Sentiment analysis',
  'Text to speech',
  'Personalizer',
  'Speaker Recognition',
  'Conversational language understanding',
  'Custom Vision',
  'Question answering',
  'Anomaly Detector',
  'Content Moderator',
  'Monitoring models',
  'AI on-the-edge',
  'Deployment and Consuming models',
];

const botServ = [
  'Custom Bots',
  'Core Assistants',
  'Q&A Makers',
  'Enterprise Assistants',
  'Bot Framework Emulators',
  'Calendar Bots',
  'People Bots',
];

const SoftwareArchitecture = () => {
  const { active, handleCardClick } = useTabCardState(1);
  return (
    <>
      <HeaderAI />

      <SectionSimple>
        <div className={styles.titleTab}>
            Our services cover the following directions:
        </div>
        <WrapFlex>
          <TabCard
            img="./img/tabCard/AIML/AIML.svg"
            title="AI & ML solutions development"
            text="Elevate your business with custom-built AI and ML solutions."
            onClick={() => handleCardClick(1)}
            active={active === 1}
          />
          <TabCard
            img="./img/tabCard/AIML/botServices.svg"
            title="Digital Assistants"
            text="Unleash the Potential of Generative AI and Bots."
            onClick={() => handleCardClick(2)}
            active={active === 2}
          />
        </WrapFlex>
      </SectionSimple>

      <SectionAD>
        {/* контент */}
        <TabContent
          title="AI & ML solutions development"
                  text="Our expert team tailors AI and ML strategies, models, and deployments to fit your unique needs, ensuring seamless integration and continuous improvement. Embrace the future of AI and ML with our comprehensive services and unlock the competitive advantage your business deserves."
          list={solDev}
          active={active === 1}
        />

        <TabContent
                  title="Digital Assistants"
                  text="Embrace the future with generative AI, capable of producing human-quality text, translating languages, and crafting creative content. Leverage sophisticated bots to automate tasks, provide 24/7 customer support, and streamline workflows."
          list={botServ}
          active={active === 2}
        />
      </SectionAD>
    </>
  );
};

export default SoftwareArchitecture;
