import styles from './ArchitectureDevelopment.module.scss';
import SectionSimple from '../../../components/SectionSimple/SectionSimple';
import WrapFlex from '../../../components/WrapFlex/WrapFlex';
import TabCard from '../../../components/TabCard/TabCard';
import HeaderArchDev from '../../../components/HeaderArchDev/HeaderArchDev';
import TabContent from '../../../components/TabContent/TabContent';

import { useTabCardState } from '../../../utils/useTabCardState';
import TabContentImg from '../../../components/TabContentImg/TabContentImg';

const webDev = [
    {
        img: './img/tabContent/NET.svg',
        text: '.Net',
    },
    {
        img: './img/tabContent/JAVA.svg',
        text: 'Java',
    },
    {
        img: './img/tabContent/JS.svg',
        text: 'JS',
    },
    {
        img: './img/tabContent/HTML.svg',
        text: 'HTML, CSS',
    },
    {
        img: './img/tabContent/ANGULAR.svg',
        text: 'Angular',
    },
    {
        img: './img/tabContent/SWIFT.svg',
        text: 'SWIFT',
    },
    {
        img: './img/tabContent/REACT.svg',
        text: 'React',
    },
    {
        img: './img/tabContent/NODE.svg',
        text: '.Node JS',
    },
    {
        img: './img/tabContent/XAMARIN.svg',
        text: 'Xamarin',
    },
];

const softArc = [
    'Software Architecture Design and Implementation',
    'Architectural Analysis',
    'Architecture Evaluation & Evolution',
    'Documentation',
    'Technical Supervision',
];

const ArchitectureDevelopment = () => {
    const { active, handleCardClick } = useTabCardState(1);

    return (
        <>
            <HeaderArchDev />
            <SectionSimple>
                <div className={styles.titleTab}>
                    “SyncSln” supplies professional services in:
                </div>
                <WrapFlex>
                    <TabCard
                        img="./img/tabCard/ArcDev/webDevelopment.svg"
                        title="Software Development"
                        text="We develop Desktop, Web, Mobile Applications and Digital Platforms."
                        onClick={() => handleCardClick(1)}
                        active={active === 1}
                    />
                    <TabCard
                        img="./img/tabCard/ArcDev/softwareArchitecture.svg"
                        title="Software Architecture"
                        text="We create unshakable foundations for your digital success."
                        onClick={() => handleCardClick(2)}
                        active={active === 2}
                    />
                </WrapFlex>

                <TabContentImg
                    title="Software Development"
                    text= "At SyncSln, we use a cutting-edge tech stack to build innovative and reliable software solutions for our customers. With our technology stack, all your business challenges are completely covered. Our stack is designed to be scalable, secure, and easy to use, so you can focus on your business while we take care of the technical aspects and impementing of your requirements and any ideas. Contact us today to learn more."
                    content={webDev}
                    active={active === 1}
                />
                <TabContent
                    title="Software Architecture"
                    text="Just as a blueprint guides the construction of a sturdy building, a well-crafted software architecture lays the foundation for a robust and scalable solution. Our expert architects can help you design and implement a software architecture that aligns with your business goals, ensuring a seamless user experience, operational efficiency, and long-term success."
                    list={softArc}
                    active={active === 2}
                />
            </SectionSimple>
        </>
    );
};

export default ArchitectureDevelopment;
