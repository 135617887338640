import styles from './HeaderAI.module.scss';
import SiteNav from '../SiteNav/SiteNav';

const HeaderAI = () => {
    return (
        <header className={styles.header}>
            <img className={styles.bgHeader} src="./img/header/bg.svg" alt="" />

            <img className={styles.eclipseR} src="./img/header/eclipseR.svg" alt="" />
            <img className={styles.cloudL} src="./img/header/cloud.svg" alt="" />
            <img className={styles.groupR} src="./img/header/Group.svg" alt="" />
            <div className={styles.container}>
                <SiteNav page="Software Architecture" />
                <img className={styles.group} src="./img/header/Group.svg" alt="" />

                <img className={styles.cloud} src="./img/header/cloud.svg" alt="" />
                <img
                    className={styles.headerTitle}
                    src="./img/servHeader/Ai.svg"
                    alt=""
                />
                <h1>AI services</h1>
                <span className={styles.paragraph}>
                    Artificial intelligence (AI) offers a transformative approach to streamlining routine processes, enhancing efficiency and productivity.
                    AI's ability to rapidly aggregate and analyze vast amounts of data enables the development of innovative solutions, drastically reducing the time spent on research and analysis.
                    This empowers organizations to make informed decisions promptly, accelerating progress and innovation.
                </span>
                <span className={styles.paragraph}>
                    SyncSln: Your Trusted AI Partner for a Transformed Future.
                </span>
            </div>
        </header>
    );
};

export default HeaderAI;
