import CardServ from '../CardServ/CardServ';
import ParagraphBl from '../ParagraphBl/ParagraphBl';
import TitleBl from '../TitleBl/TitleBl';
import styles from './ServiSection.module.scss';
import serviceObj from '../../data/serviceObj';

const ServiSection = () => {
    return (
        <div id="services" className={styles.serviSection}>
            <div className={styles.container}>
                <TitleBl>Our services</TitleBl>
                <ParagraphBl>
                    At SyncSln, we offer comprehensive software solutions in leading fields such as Cloud Engineering, AI & ML, and IoT.
                    Our seasoned  team of software architects and developers is equipped to support you at any stage of your project lifecycle, from planning and design to implementation and testing.
                    We also provide  top-tier technical advisory and audit services to ensure that your solutions are secure, scalable, and meets your unique requirements.
                </ParagraphBl>
                <ParagraphBl>
                    We provide a wide range of services designed to empower your business and unlock it's maximum potential:
                </ParagraphBl>
                <div className={styles.wrapCard}>
                    {serviceObj.map((service) => {
                        return <CardServ service={service} key={service.id} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default ServiSection;
