import styles from './InternetThings.module.scss';
import SectionSimple from '../../../components/SectionSimple/SectionSimple';
import WrapFlex from '../../../components/WrapFlex/WrapFlex';
import TabCard from '../../../components/TabCard/TabCard';
import HeaderIoT from '../../../components/HeaderIoT/HeaderIoT';
import SectionAD from '../../../components/SectionAD/SectionAD';
import TabContent from '../../../components/TabContent/TabContent';

import { useTabCardState } from '../../../utils/useTabCardState';

const iOtDev = [
    'Devices & Device communication',
    'Device provisioning',
    'Message processing & Analytics',
    'IoT Central Solutions',
    'Solution testing & Diagnostics and monitoring',
    'IoT software enhancement',
    'IoT acceleration',
    'IoT analytics',
    'Digital Twins',
    'Condition monitoring',
    'Asset Tracking solutions',
    'Equipment monitoring',
    'BOPIS Solutions',
    'User app development',
];

const iOtCon = [
    'Strategic Technology Integration',
    'Process Digitization',
    'Digital Solution Concept Evaluation',
    'Expert Project Management',
    'Rigorous IoT Solution Testing',
    'Comprehensive Economic Feasibility Analysis',
    'Innovative Digital Use Case Development',
    'Insight and business integration',
    'IoT Security considerations',
    'IoT Reference architecture design & Implementation',
];

const iOtEdge = [
    'Real-time data processing',
    'Optimizing manufacturing processes',
    'IoT data analytics',
    'IoT using Cosmos DB',
    'Connected Factory Hierarchy Service',
    'Predictive maintenance with IoT',
    'Monitor equipment health',
    'Computer vision on the edge'
];

const InternetThings = () => {
    const { active, handleCardClick } = useTabCardState(1);
    return (
        <>
            <HeaderIoT />
            <SectionSimple>
                <div className={styles.titleTab}></div>

                <WrapFlex>
                    <TabCard
                        img="./img/tabCard/IoT/Full-cycle-IoT-development.svg"
                        title="Full-cycle IoT development"
                        text="Concept Development and Prototyping, Hardware Design and Engineering, Software Development, Cloud Platform Integration, Testing and Deployment - from one hand at SyncSln."
                        onClick={() => handleCardClick(1)}
                        active={active === 1}
                    />
                    <TabCard
                        img="./img/tabCard/IoT/IoT-consulting.svg"
                        title="IoT consulting"
                        text="Unleash the Potential of IoT with Our Comprehensive Consulting Services."
                        onClick={() => handleCardClick(2)}
                        active={active === 2}
                    />
                    <TabCard
                        img="./img/tabCard/IoT/IoT-on-the-edge.svg"
                        title="IoT on the edge"
                        text="Future-proof your business with our scalable and secure IoT on the edge platform."
                        onClick={() => handleCardClick(3)}
                        active={active === 3}
                    />
                </WrapFlex>
            </SectionSimple>

            <SectionAD>
                {/* контент */}
                <TabContent
                    title="Full-cycle IoT development"
                    text="We offer a comprehensive suite of IoT development services that encompass the entire project lifecycle, ensuring a seamless and efficient journey from ideation to deployment:"
                    list={iOtDev}
                    active={active === 1}
                />

                <TabContent
                    title="IoT consulting"
                    text="Embrace the interconnected future of industry with SyncSln's visionary IoT consulting services. We empower your business to:"
                    list={iOtCon}
                    active={active === 2}
                />

                <TabContent
                    title="IoT on the edge"
                    text="Unleash the power of IoT with our comprehensive edge computing solutions. Our services enable you to seamlessly collect, analyze, and act on data from your connected devices, all in real time."
                    list={iOtEdge}
                    active={active === 3}
                />
            </SectionAD>
        </>
    );
};

export default InternetThings;
