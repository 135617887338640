import styles from './HeaderCloud.module.scss';
import SiteNav from '../SiteNav/SiteNav';

const HeaderCloud = () => {
  return (
    <header className={styles.header}>
      <img className={styles.bgHeader} src="./img/header/bg.svg" alt="" />

      <img className={styles.eclipseR} src="./img/header/eclipseR.svg" alt="" />
      <img className={styles.cloudL} src="./img/header/cloud.svg" alt="" />
      <img className={styles.groupR} src="./img/header/Group.svg" alt="" />
      <div className={styles.container}>
        <SiteNav page="Cloud Services" />
        <img className={styles.group} src="./img/header/Group.svg" alt="" />

        <img className={styles.cloud} src="./img/header/cloud.svg" alt="" />
        <img
          className={styles.headerTitle}
          src="./img/servHeader/Cloud.svg"
          alt=""
        />
        <h1>Cloud Services</h1>
        <span className={styles.paragraph}>
            SyncSln is a cloud solutions expert. We design, redesign, and advise on cloud solutions, and we can also create new ones from scratch.
        </span>
      </div>
    </header>
  );
};

export default HeaderCloud;
