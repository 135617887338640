import styles from './HeaderArchDev.module.scss';
import SiteNav from '../SiteNav/SiteNav';

const HeaderArchDev = () => {
  return (
    <header className={styles.header}>
      <img className={styles.bgHeader} src="./img/header/bg.svg" alt="" />

      <img className={styles.eclipseR} src="./img/header/eclipseR.svg" alt="" />
      <img className={styles.cloudL} src="./img/header/cloud.svg" alt="" />
      <img className={styles.groupR} src="./img/header/Group.svg" alt="" />
      <div className={styles.container}>
        <SiteNav page="Software Architecture & Development" />
        <img className={styles.group} src="./img/header/Group.svg" alt="" />

        <img className={styles.cloud} src="./img/header/cloud.svg" alt="" />
        <img
          className={styles.headerTitle}
          src="./img/servHeader/ArchiDev.svg"
          alt=""
        />
        <h1>Architecture & Development</h1>
              <span className={styles.paragraph}>
                  In today's digital world and business landscape, a powerful digital presence is essential for businesses of all sizes.
                  A well-designed and expertly developed applications, with a balanced architecture, is one of the most effective ways to boost your performance, demonstrate your leadership, and highlight your expertise.
              </span>
      </div>
    </header>
  );
};

export default HeaderArchDev;
