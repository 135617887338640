let id = 0;

const services = [
    {
        id: id++,
        img: './img/ServicesCard/Software.svg',
        link: 'architecture-development',
        title: 'Software',
        li_1: 'Design & Develop from scratch',
        li_2: 'Fix & Modernization Legacy Code',
        li_3: 'Advisory & Technical Support'
    },
    {
        id: id++,
        img: './img/tabCard/Cloud/cloudMigration.svg',
        link: 'cloud',
        title: 'Cloud',
        li_1: 'Build & Improve Infrastructure',
        li_2: 'Re-architecture & Migration',
        li_3: 'Engineering & Security'
    },
    {
        id: id++,
        img: './img/tabCard/AIML/AIML.svg',
        link: 'software-architecture',
        title: 'AI/ML',
        li_1: 'Intelligent Automation & Production',
        li_2: 'Digital Assistants',
        li_3: 'Predictive Analytics & Maintenance'
    },
    {
        id: id++,
        img: './img/ServicesCard/IoT.svg',
        link: 'internet-of-things',
        title: 'IoT',
        li_1: 'Standalone device software',
        li_2: 'Remote Management & Automation',
        li_3: 'Architecture-Infrastructure Integration'
    }
];

export default services;
